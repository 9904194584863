import { KOTDShop } from "../components"

function Shop() {
  return (
    <div style={{margin: 'auto'}}>
        <KOTDShop />
    </div>
  );
}

export default Shop;