import { RaidMath } from "../components"

function Raid() {
  return (
    <div style={{margin: 'auto'}}>
        <RaidMath />
    </div>
  );
}

export default Raid;