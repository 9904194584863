import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
// eslint-disable-next-line no-unused-vars
import * as Pages from "./pages";
import * as Components from './components';

sessionStorage.setItem('tabID', new Date().getTime());

export default function App() {
  const isAuthenticated = localStorage.getItem('storedPlayerName'); 
  const storedPlayerData = JSON.parse(localStorage.getItem('storedPlayerData') || '{"permissions":[]}') ; 
  const {
    permissions: playerPermissions = []
  } = storedPlayerData;

  const fallBack = <Navigate to="boss-list" replace />;

  const permissionCheck = (requiredPermissions = []) => {
    return requiredPermissions?.length ? requiredPermissions.some(permission => playerPermissions.includes(permission)) : true;
  };

  const routes = [
    {
      path: "boss-list",
      element: <Pages.Home />,
      authorized: true
    },
    {
      path: 'shop',
      element: <Pages.Shop />,
      authorized: true
    },
    {
      path: '1000Doors',
      element: <Components.ThousandDoors />,
      authorized: !!isAuthenticated
    },
    {
      path: 'inventory',
      element: <Pages.Inventory />,
      authorized: !!isAuthenticated
    },
    {
      path: 'raid',
      element: <Pages.Raid />,
      authorized: !!isAuthenticated
    },
    {
      path: '1000Doors/report',
      element: <Components.ThousandDoorsReport />,
      authorized: !!isAuthenticated && permissionCheck(["1000Doors/report"])
    }
  ];
  
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Pages.Layout />}>
          <Route index element={fallBack} />\
          {routes.map((route, r) => <Route path={route.path} element={route.authorized ? route.element : fallBack} key={r} />)}
          <Route path="*" element={fallBack} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
