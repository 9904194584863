import { PlayerInventory } from "../components"

function Inventory() {
  return (
    <div style={{margin: 'auto'}}>
        <PlayerInventory />
    </div>
  );
}

export default Inventory;