import { Outlet, Link } from "react-router-dom";
import { useState, Fragment } from 'react';
import useSWR from 'swr';
import PropTypes from 'prop-types';
import { AppBar, Box, CssBaseline, Divider, SwipeableDrawer, IconButton,
         List, ListItem, ListItemButton, Toolbar, Typography, useScrollTrigger,
         Slide, Menu, MenuItem, Button, ListSubheader, CircularProgress, Backdrop  } from '@mui/material';

import * as MUIIcons from '@mui/icons-material';

import { Link as ReactRouterLink } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import "../App.css";
import { PlayerDetails, Announcements } from "../components";

const fetcher = (...args) => fetch(...args).then((res) => res.json());

function HideOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired
};


const Layout = (props) => {

  const drawerWidth = 240;

  //const MenuIcon = MUIIcons.Menu
    
  const { window } = props;
  const [ mobileOpen, setMobileOpen ] = useState(false);
  const [ storedPlayerName ] = useState(localStorage.getItem('storedPlayerName') || false);
  const [ navigation, setNav ] = useState([]);
  const [ anchorEl, setAnchorEl ] = useState({});

  const updateNav = data => {
    setNav(data);
    setAnchorEl(data.filter(navItem => navItem.type === 'menu').reduce((obj, navItem) => ({...obj, [navItem.title]: null}), {}));
  }

  const isMenuOpen = (menu) => {
    return Boolean(anchorEl[menu])
  }
  
  const eventMenuClick = (event, menu) => {
    setAnchorEl({
      ...anchorEl,
      [menu]: event.currentTarget
    });
  };

  const eventMenuClose = (menu) => {
    setAnchorEl({
      ...anchorEl,
      [menu]: null
    });
  };


  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const {
    error,
    isValidating,
  } = useSWR(`https://api.spaghet.io/kotd/v1/navigation/${storedPlayerName}`, fetcher, { onSuccess: updateNav, revalidateOnFocus: false});

  if(error || isValidating) return <Backdrop
      sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
      open={true}
    >
      <CircularProgress color="secondary" />
    </Backdrop>
  
  
  const mobileNav = navigation.map((navItem, n) => {
    const {
      icon,
      title
    } = navItem;

    const MUIIcon = MUIIcons[icon];

    switch(navItem.type) {
      case 'menu': 
        const {
          items
        } = navItem;

        return <Fragment key={title}>
          <ListSubheader sx={{fontWeight: 'bolder', bgcolor: 'primary.main', color: '#FFF'}}  divider={<Divider />}><MUIIcon sx={{verticalAlign:'middle'}} />&nbsp;{title}</ListSubheader>
          {items.map((item, i) => {
            const {
              icon: itemIcon,
              title: itemTitle,
              to: itemTo
            } = item;

            const MUIItemIcon = MUIIcons[itemIcon];

            return <ListItem key={itemTitle} disablePadding>
            <ListItemButton component={ReactRouterLink} sx={{ textAlign: 'center' }} to={itemTo} divider={true}>
              <MUIItemIcon sx={{verticalAlign:'middle'}}  />&nbsp;{itemTitle}
            </ListItemButton>
          </ListItem>
          })}
        </Fragment>
      default:
        const {
          to
        } = navItem;

        return <ListItem key={title} disablePadding>
          <ListItemButton component={ReactRouterLink} sx={{ textAlign: 'center' }} to={to} divider={true}>
            <MUIIcon sx={{verticalAlign:'middle'}}  />&nbsp;{title}
          </ListItemButton>
        </ListItem>
    }
  });

  const desktopNav = navigation.map((navItem, n) => {
    const {
      icon,
      title
    } = navItem;

    const MUIIcon = MUIIcons[icon];

    switch(navItem.type) {
      case 'menu': 
        const {
          items
        } = navItem;

        return <Fragment key={title}>
          <Button
            id={`${title}-button`}
            aria-controls={isMenuOpen(title) ? `${title}-menu` : undefined}
            aria-haspopup="true"
            aria-expanded={isMenuOpen(title) ? 'true' : undefined}
            onClick={(event) => eventMenuClick(event, title)}
            variant="text"
            sx={{color: '#FFF'}}
            >
              <MUIIcon  sx={{verticalAlign:'middle'}} />&nbsp;{title}
          </Button>
          <Menu
            id={`${title}-menu`}
            anchorEl={anchorEl[title]}
            open={isMenuOpen(title)}
            onClose={() => eventMenuClose(title)}
            MenuListProps={{
              'aria-labelledby': `${title}-button`,
            }}
          >
            {items.map((item, i) => {
              const {
                icon: itemIcon,
                title: itemTitle,
                to: itemTo
              } = item;

              const MUIItemIcon = MUIIcons[itemIcon];

              return <MenuItem key={itemTitle} onClick={() => eventMenuClose(title)} >
              <Link component={ReactRouterLink} to={itemTo} sx={{verticalAlign:'middle'}}>
                <MUIItemIcon sx={{verticalAlign:'middle'}} />&nbsp;{itemTitle}
              </Link>
            </MenuItem>
            })}
          </Menu>
        </Fragment>
      default:
        const {
          to
        } = navItem;

        return <Link component={ReactRouterLink} key={title} style={{color: '#FFF', margin: '10px', verticalAlign:'middle'}}  to={to}>
          <MUIIcon sx={{verticalAlign:'middle'}} />&nbsp;{title}
        </Link>
    }
  });

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{color: '#FFF'}}>
      <Typography variant="h6" sx={{ my: 2, textAlign: 'center', margin: '0', bgcolor: 'primary.main', height: 65, lineHeight: '65px' }}>
        Spaghet KOTD v{process.env.REACT_APP_VERSION}
      </Typography>
      <Divider />
      <List children={mobileNav} />
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <CssBaseline />
      <HideOnScroll {...props}>
        <AppBar component="nav">
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { lg: 'none' } }}
            >
              <MUIIcons.Menu />
            </IconButton>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, display: {  xs: 'none', sm: 'none', md: 'none', lg: 'block' } }}
            >
              Spaghet KOTD <small>v{process.env.REACT_APP_VERSION}</small>
            </Typography>
            <Box sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'block' } }} children={desktopNav} />
            <Box sx={{ margin: 'auto'}}>
              <PlayerDetails />
            </Box>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <nav>
        <SwipeableDrawer
          container={container}
          swipeAreaWidth={isMobile ? 20 : 0}
          minFlingVelocity={500}
          variant="temporary"
          open={mobileOpen}
          onOpen={handleDrawerToggle}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'block', md: 'block', lg: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, bgcolor: 'secondary.main' }
          }}
        >
          {drawer}
        </SwipeableDrawer>
      </nav>
      <div className="App-base">
        <Announcements/>
        <Outlet/>
        <footer>
          <p style={{color: '#FF'}}>This tool is provided by u/SpaghetGaming for personal use as well as the KOTD Discord</p>
        </footer>
      </div>
    </>
  )
};

export default Layout;